import httpUtil from "@/utils/httpUtil";

/**优惠券列表 */
export const crmPcPageList = async params => httpUtil.post("/company/coupon/crmPcPageList", params);

/**发布状态 */
export const updState = async params => httpUtil.post("/company/coupon/updState", params);

/**商品分类 */
export const getGoodsTypeInfo = async params => httpUtil.post("/goods/getGoodsTypeInfo", params);

/**
 * 修改商品排序
 * @param params
 * @returns {Promise<*>}
 */
export const updateGoodsSort = async params => httpUtil.post("/companyGoods/updateGoodsSort", params);

/**
 * 修改分类排序
 * @param params
 * @returns {Promise<*>}
 */
export const updateGoodsTypeSort = async params => httpUtil.post("/goods/updateGoodsTypeSort", params);


/**商品分类列表 */
export const selectGoodsTypeInfoPC = async params => httpUtil.post("/goods/selectGoodsTypeInfoPC", params);


/**商品分类新增 */
export const addGoodsType = async params => httpUtil.post("/goods/addGoodsType", params);


//删除分类
export const delGoodsType = async params => httpUtil.post("/goods/delGoodsType", params);

//删除分类
export const updateOnline = async params => httpUtil.post("/goods/updateOnline", params);


//商品列表
export const getGoodsInfo = async params => httpUtil.post("/goods/getGoodsInfoPC", params);

//编辑分类
export const selectGoodsType = async params => httpUtil.post("/goods/selectGoodsTypeInfoPC", params);


//删除商品
export const delGoods = async params => httpUtil.post("/goods/delGoodsInfo", params);

//新增商品
export const addGoods = async params => httpUtil.post("/goods/addGoodsInfoList", params);


/**发布优惠券 */
export const add = async params => httpUtil.post("/company/coupon/add", params);

/**删除优惠券 */
export const del = async params => httpUtil.post("/company/coupon/del", params);

/**编辑优惠券 */
export const upd = async params => httpUtil.post("/company/coupon/upd", params);


/**优惠券领取记录 */
export const coupponRecords = async params => httpUtil.post("/company/coupon/coupponRecords", params);

/***不分页的商品列表 */

export const fetchGoodsListAll =  async params => httpUtil.get("/goods/goodListNoPage", params);

/**指定中奖人发放优惠券 */
export const fetchAddWinCoupon =  async params => httpUtil.post("/shopConfig/addWinCoupon", params);

/**优惠券发放记录接口 */
export const fetchWinCouponList =  async params => httpUtil.get("/shopConfig/winCouponList", params);

/***删除优惠券某个人*/
export const fetchDeleteCouponList =  async params => httpUtil.post("/shopConfig/delWinCoupon", params);

/***新增/编辑搜索热词 */
export const fetchAddHotWord =  async params => httpUtil.post("/searchRecord/add", params);

/*** 搜索热词列表****/
export const fetchHotWordList =  async params => httpUtil.get("/searchRecord/list", params);


